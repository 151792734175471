import React, { useState } from "react";
import { Box, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import {
  formatDateTimeStringUTCISO8601toParisDisplay,
  isDateTodayOrPast,
} from "../../utils/date";
import { contractPerimeterStyle, errorMessages, statusStyle } from "../../utils/constants";
import { IOrder } from "../../types/orders";
import { convertValueToOption, formatAPIRequestErrorMessage } from "../../utils/format";
import { useAppContext } from "../../DataProvider";
import { useAuthToken } from "../../app/hooks/useAuthToken";

interface IOrdersTableProps {
  onEdit: (order: IOrder) => void;
  onDelete: (order: IOrder) => void;
}

const getCellStyles = (status: string, style: any) => {
  if (status in style) {
    return style[status];
  } else {
    return {
      background: "#fff",
      color: "black",
    };
  }
};

const OrdersTable: React.FC<IOrdersTableProps> = ({ onEdit, onDelete }) => {
  const { state, dispatch, getOrdersWithFilters } = useAppContext();
  const token = useAuthToken();
  const [sort, setSort] = useState("");
  const orders = state.orders;
  const contractPerimeters = state.contractPerimeters;
  const companies = state.companies;

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "deliveryStartDate",
      headerName: "Delivery Start Date",
      width: 140,
    },
    {
      field: "status",
      headerName: "Status",
      width: 95,
      renderCell: (params) => {
        const { background, color } = getCellStyles(params.value, statusStyle);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: background,
              color: color,
              borderRadius: "4px",
              padding: "4px 8px",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    { field: "volume", headerName: "Volume (MW)", width: 105 },
    { field: "acceptedVolume", headerName: "Traded Volume (MW)", width: 130},
    { field: "price", headerName: "Bid Price (€)", width: 90 },
    { field: "clearingPrice", headerName: "Clearing Price (€)", width: 130},
    { field: "supplierBalance", headerName: "Supplier Balance", width: 115},
    { field: "side", headerName: "Side", width: 50 },
    {
      field: "contractPerimeterName",
      headerName: "Contract Perimeter",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        const { background, color } = getCellStyles(
          params.value,
          contractPerimeterStyle
        );
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: background,
              color: color,
              borderRadius: "4px",
              padding: "4px 8px",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    { field: "companyName", headerName: "Company", sortable: false, width: 80 },
    { field: "nemo", headerName: "Nemo", width: 80 },
    { field: "market", headerName: "Market", width: 120 },
    { field: "note", headerName: "Note", width: 140 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 90,
      getActions: (params) => {
        const disableActions = isDateTodayOrPast(params.row.deliveryStartDate);
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="text-primary"
            onClick={() => onEdit(params.row as IOrder)}
            color="inherit"
            disabled={disableActions}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            className="text-primary"
            onClick={() => onDelete(params.row as IOrder)}
            color="inherit"
            disabled={disableActions}
          />,
        ];
      },
    },
    { field: "updated_at", headerName: "Updated At Date", width: 120 },
  ];

  const rows = orders.map((order) => {
    const contractPerimeter = contractPerimeters.find(
      (cp) => cp.id === order.contract_perimeter_id
    );
    const company = companies.find(
      (c) => c.id === (contractPerimeter?.company_id || "")
    );
    const contractPerimeterName = contractPerimeter?.name || "";
    const companyName = company?.name || "";

    return {
      id: order.id,
      deliveryStartDate: formatDateTimeStringUTCISO8601toParisDisplay(
        order.delivery_start_date
      ),
      status: convertValueToOption(order.status, "status"),
      volume: order.volume,
      acceptedVolume: order.accepted_volume,
      price: order.price,
      clearingPrice: order.clearing_price,
      updated_at: formatDateTimeStringUTCISO8601toParisDisplay(
        order.updated_at
      ),
      supplierBalance: order.supplier_balance ? order.supplier_balance.toFixed(2): null,
      side: order.side,
      company: order.company,
      contractPerimeterName: contractPerimeterName,
      companyName: companyName,
      nemo: convertValueToOption(order.nemo, "nemo"),
      market: convertValueToOption(order.market, "market"),
      note: order.note,
    };
  });

  if (orders.length === 0) {
    return <Alert severity="error">No results for the applied filters</Alert>;
  }

  const handlePaginationModelChange = async (newModel: any) => {
    if (newModel.page !== state.page - 1 && token) {
      const pagefilters = {
        ...state.filters,
        page: newModel.page + 1,
        order_by: sort,
      };
      try {
        const ordersResponse = await getOrdersWithFilters(pagefilters, token);

        dispatch({ type: "GET_ORDERS_SUCCESS", payload: ordersResponse.items });
        dispatch({
          type: "TABLE_INFO_UPDATED",
          payload: {
            totalOrders: ordersResponse.total,
            page: ordersResponse.page,
            pages: ordersResponse.pages,
          },
        });
      } catch (error: any) {
        const errorMessage = formatAPIRequestErrorMessage(error, errorMessages.GET_ORDERS_PAGINATION);
        dispatch({
          type: "SET_NOTIFICATION",
          payload: { message: errorMessage, type: "error" },
        });
      }
    }
  };

  const handleColumnHeaderClick = async (params: any) => {
    const field = params.field;
    let tmpSort = "";
    if (field === "actions" || field === "contractPerimeterName" ||  field === "companyName" ||  !token) {
      return;
    }
    if (sort === field) {
      tmpSort = `-${field}`;
      setSort(tmpSort);
    } else if (sort === `-${field}`) {
      tmpSort = "";
      setSort(tmpSort);
    } else {
      tmpSort = field;
      setSort(tmpSort);
    }
    const sortfilters = {
      ...state.filters,
      order_by: tmpSort,
      page: state.page,
    };
    try {
      const ordersResponse = await getOrdersWithFilters(sortfilters, token);

      dispatch({ type: "GET_ORDERS_SUCCESS", payload: ordersResponse.items });
      dispatch({
        type: "TABLE_INFO_UPDATED",
        payload: {
          totalOrders: ordersResponse.total,
          page: ordersResponse.page,
          pages: ordersResponse.pages,
        },
      });
    } catch (error: any) {
      const errorMessage = formatAPIRequestErrorMessage(error, errorMessages.GET_ORDERS_SORT);
      dispatch({
        type: "SET_NOTIFICATION",
        payload: { message: errorMessage, type: "error" },
      });
    }
  };

  return (
    <div style={{ height: "88vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[100]}
        rowSelection={false}
        rowCount={state.totalOrders}
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
        pagination
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        sortingMode="server"
        onColumnHeaderClick={handleColumnHeaderClick}
        disableVirtualization
      />
    </div>
  );
};

export default OrdersTable;
