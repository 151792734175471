import { DateTime } from 'luxon';

export const isDateTodayOrPast = (dateStr: string) => {
  const deliveryDateTime = DateTime.fromFormat(dateStr, "dd/MM/yyyy HH:mm", { zone: 'Europe/Paris' });
  const nowInParis = DateTime.now().setZone('Europe/Paris');
  const todayNoonInParis = nowInParis.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  return deliveryDateTime <= todayNoonInParis;
};


export function formatDateTimeStringUTCISO8601toParisDisplay(isoDateString: string): string {
  const utcDate = DateTime.fromISO(isoDateString, { zone: 'utc' });
  const parisDate = utcDate.setZone('Europe/Paris');
  const formattedDate = parisDate.toFormat('dd/MM/yyyy HH:mm');
  
  return formattedDate;
}

export const formatInputParisDateandTimetoUTCISO8601 = (dateString: string, hour: string = "00", minute: string = "00", second: string = "00"): string => {
  const year = Number(dateString.split("-")[0]);
  const month = Number(dateString.split("-")[1]);
  const day = Number(dateString.split("-")[2]);
  const parsedHour = Number(hour);
  const parsedMinute = Number(minute);
  const parsedSecond = Number(second);

  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(parsedHour) || Number.isNaN(parsedMinute) || Number.isNaN(parsedSecond)) {
    return ""; 
  }

  const parisDateTime = DateTime.fromObject({
    year: year,
    month: month,
    day: day,
    hour: parsedHour,
    minute: parsedMinute,
    second: parsedSecond,
  }, { zone: 'Europe/Paris' });

  if (!parisDateTime.isValid) {
    return ""; 
  }


  const utcDateTimeISO = parisDateTime.toUTC().toISO();
  return utcDateTimeISO ? utcDateTimeISO : ""; 
};

export const formatInputUTCDateandTimetoISO8601 = (dateString: string, hour: string = "00", minute: string = "00", second: string = "00"): string => {

  const utcDateTime = DateTime.fromObject({
    year: Number(dateString.split("-")[0]),
    month: Number(dateString.split("-")[1]),
    day: Number(dateString.split("-")[2]),
    hour: Number(hour),
    minute: Number(minute),
    second: Number(second),
  }, { zone: 'Europe/Paris' });


  if (!utcDateTime.isValid) {
    return ""; 
  }

  const utcDateTimeISO = utcDateTime.toISO();
  return utcDateTimeISO ? utcDateTimeISO : "";
};

export const getParisDay = (numDays: number) => {
  const currentDateInParis = DateTime.now().setZone('Europe/Paris');
  const targetDateInParis = currentDateInParis.plus({ days: numDays });
  let formattedDate = targetDateInParis.toFormat('yyyy-MM-dd');
  return formattedDate;
};

export function generateHourList(type?: string): string[] {
  const startHour = (type === 'end') ? 1 : 0;
  const endHour = (type === 'start') ? 23 : 24;
  const hoursList = Array.from({ length: endHour - startHour + 1 }, (_, index) =>
    (index + startHour).toString().padStart(2, "0")
  );

  return hoursList;
}