import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useAppContext } from "../../DataProvider"; 

const Notification = () => {
  const { state, dispatch } = useAppContext(); 
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const notification = state.notification; 
    if (notification.message && notification.type) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        dispatch({ type: "CLEAR_NOTIFICATION" }); 
      }, 12000);

      return () => clearTimeout(timer);
    }
  }, [state.notification, dispatch]);

  const handleClose = (event: React.SyntheticEvent<any, Event> | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLEAR_NOTIFICATION" }); 
  };

  return (
    <Snackbar open={open} autoHideDuration={12000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={state.notification.type ?? undefined} 
        sx={{ width: "100%" }}
      >
        {state.notification.message} 
      </Alert>
    </Snackbar>
  );
};

export default Notification;
