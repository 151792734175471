import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useAuthToken } from "../../app/hooks/useAuthToken";
import { DateTime } from "luxon";
import {
  errorMessages,
  filtersOptions,
  filtersValues,
} from "../../utils/constants";
import {
  formatInputParisDateandTimetoUTCISO8601,
  getParisDay,
  generateHourList,
} from "../../utils/date";
import {
  convertToFilterValue,
  convertToFilterValues,
  formatAPIRequestErrorMessage,
} from "../../utils/format";
import { useAppContext } from "../../DataProvider";

const Filters: React.FC<{}> = () => {
  const { state, dispatch, getOrdersWithFilters } = useAppContext();
  const token = useAuthToken();
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [selectedNemos, setSelectedNemos] = useState<string>("");
  const [selectedMarkets, setSelectedMarkets] = useState<string>("");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [startHour, setStartHour] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [endHour, setEndHour] = useState<string>("");

  const [companyOptions, setCompanyOptions] = useState<string[]>([]);
  const [contractOptions, setContractOptions] = useState<string[]>([]);
  const [nemoOptions, setNemoOptions] = useState<string[]>([]);
  const [marketOptions, setMarketOptions] = useState<string[]>([]);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);

  const tomorrow = getParisDay(1);
  const dayAfterTomorrow = getParisDay(2);

  useEffect(() => {
    const companyOptions = state.companies.map((company) => company.name);
    setCompanyOptions(companyOptions);
    const contractOptions = state.contractPerimeters.map(
      (contract) => contract.name
    );
    setContractOptions(contractOptions);
    setNemoOptions(filtersOptions.nemo);
    setMarketOptions(filtersOptions.market);
    setStatusOptions(filtersOptions.status);
    setStartDate(tomorrow);
    setEndDate(dayAfterTomorrow);
  }, [state.companies, state.contractPerimeters, tomorrow, dayAfterTomorrow]);

  const resetFilters = () => {
    setSelectedCompanies([]);
    setSelectedContracts([]);
    setSelectedNemos("");
    setSelectedMarkets("");
    setSelectedStatuses([]);
    setStartDate(tomorrow);
    setEndDate(dayAfterTomorrow);
    setStartHour("");
    setEndHour("");
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputDate = event.target.value;
    const startDate = DateTime.fromISO(inputDate);
    if (!startDate.isValid) return;
    const endDateLuxon = DateTime.fromISO(endDate);

    setStartDate(inputDate);

    if (endDateLuxon < startDate) {
      const newEndDate = startDate.plus({ days: 1 }).toISODate();
      setEndDate(newEndDate ? newEndDate : "");
    }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEndDate = event.target.value;
    const endDate = DateTime.fromISO(inputEndDate);
    if (endDate.isValid) {
      setEndDate(inputEndDate);

      const startDateLuxon = DateTime.fromISO(startDate);

      if (endDate < startDateLuxon) {
        const newStartDate = endDate.minus({ days: 1 }).toISODate();
        setStartDate(newStartDate);
      }
    } else {
      console.error("La date de fin entrée n'est pas valide.");
    }
  };

  useEffect(() => {
    if (startDate === endDate) {
      if (startHour && endHour && parseInt(startHour) >= parseInt(endHour)) {
        if (parseInt(endHour) < 23) {
          setEndHour((parseInt(startHour) + 1).toString().padStart(2, "0"));
        } else {
          setEndHour("00");
          const newEndDate = DateTime.fromISO(startDate)
            .plus({ days: 1 })
            .toISODate();
          setEndDate(newEndDate ? newEndDate : "");
        }
      }
    }
  }, [startHour, endHour, startDate, endDate]);

  useEffect(() => {
    const fetchFilteredOrders = async (token: string) => {
      const companyIds = selectedCompanies
        .map((companyName) => {
          const company = state.companies.find((c) => c.name === companyName);
          return company ? company.id : null;
        })
        .filter((id) => id !== null) as string[];

      const contractIds = selectedContracts
        .map((contractName) => {
          const contract = state.contractPerimeters.find(
            (cp) => cp.name === contractName
          );
          return contract ? contract.id : null;
        })
        .filter((id) => id !== null) as string[];

      const filters = {
        company__id__in: companyIds,
        contract_perimeter__id__in: contractIds,
        nemo: convertToFilterValue(
          selectedNemos,
          filtersOptions.nemo,
          filtersValues.nemo
        ),
        market: convertToFilterValue(
          selectedMarkets,
          filtersOptions.market,
          filtersValues.market
        ),
        status__in: convertToFilterValues(
          selectedStatuses,
          filtersOptions.status,
          filtersValues.status
        ),
        delivery_start_date__gte: formatInputParisDateandTimetoUTCISO8601(
          startDate,
          startHour
        ),
        delivery_end_date__lte: formatInputParisDateandTimetoUTCISO8601(
          endDate,
          endHour
        ),
      };
      try {
        const ordersResponse = await getOrdersWithFilters(filters, token);

        dispatch({ type: "GET_ORDERS_SUCCESS", payload: ordersResponse.items });
        dispatch({
          type: "TABLE_INFO_UPDATED",
          payload: {
            totalOrders: ordersResponse.total,
            page: ordersResponse.page,
            pages: ordersResponse.pages,
          },
        });
        dispatch({ type: "FILTERS_UPDATED", payload: filters });
      } catch (error: any) {
        const errorMessage = formatAPIRequestErrorMessage(
          error,
          errorMessages.GET_ORDERS_FILTERS
        );
        dispatch({
          type: "SET_NOTIFICATION",
          payload: { message: errorMessage, type: "error" },
        });
      }
    };
    if (token) fetchFilteredOrders(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    selectedCompanies,
    selectedContracts,
    selectedNemos,
    selectedMarkets,
    selectedStatuses,
    startDate,
    endDate,
    startHour,
    endHour,
    state.companies,
    state.contractPerimeters,
    dispatch,
  ]);

  const renderSingleSelect = (
    label: string,
    selected: string,
    setSelected: React.Dispatch<React.SetStateAction<string>>,
    options: string[]
  ) => (
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`} style={{ backgroundColor: "white" }}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`}
        value={selected}
        data-testid={`${label}-select`}
        onChange={(event) => setSelected(event.target.value as string)}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderMultiSelect = (
    label: string,
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    options: string[]
  ) => (
    <FormControl fullWidth sx={{ maxWidth: "165px" }}>
      <InputLabel id={`${label}-label`} style={{ backgroundColor: "white" }}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-select`}
        data-testid={`${label}-select`}
        multiple
        value={selected}
        onChange={(event) => setSelected(event.target.value as string[])}
        renderValue={(selected) => selected.join(", ")}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Box
      data-testid="Orders-Filters"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
        gap: 2,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          mb: 2,
        }}
      >
        {renderMultiSelect(
          "Company",
          selectedCompanies,
          setSelectedCompanies,
          companyOptions
        )}

        {renderMultiSelect(
          "Contract",
          selectedContracts,
          setSelectedContracts,
          contractOptions
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          mb: 2,
        }}
      >
        {renderSingleSelect(
          "Nemo",
          selectedNemos,
          setSelectedNemos,
          nemoOptions
        )}
        {renderSingleSelect(
          "Market",
          selectedMarkets,
          setSelectedMarkets,
          marketOptions
        )}
        {renderMultiSelect(
          "Status",
          selectedStatuses,
          setSelectedStatuses,
          statusOptions
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          width: "100%",
          mb: 1,
        }}
      >
        <Typography sx={{ width: "100%", textAlign: "start" }}>
          Start
        </Typography>
        <TextField
          label="Start Trade Date"
          type="date"
          data-testid="start-date-input"
          value={startDate}
          onChange={handleStartDateChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        {renderSingleSelect(
          "Start Hour",
          startHour,
          setStartHour,
          generateHourList("start")
        )}
        <Typography sx={{ width: "100%", textAlign: "start" }}>End</Typography>
        <TextField
          label="End Trade Date"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          data-testid="end-date-input"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        {renderSingleSelect(
          "End Hour",
          endHour,
          setEndHour,
          generateHourList("start")
        )}
      </Box>

      <Button
        variant="outlined"
        color="secondary"
        onClick={resetFilters}
        sx={{ mt: 2 }}
      >
        Reset All Filters
      </Button>
    </Box>
  );
};

export default Filters;
