import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useAuthToken } from "../app/hooks/useAuthToken";
import CircularProgress from "@mui/material/CircularProgress";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { useAppContext } from "../DataProvider";
import OrdersDashboard from "../components/common/OrdersDashboard";
import OrdersButtons from "../components/common/OrdersButtons";
import OrdersFilters from "../components/common/OrdersFilters";
import OrdersTabs from "../components/common/OrdersTabs";
import {
  formatInputParisDateandTimetoUTCISO8601,
  getParisDay,
} from "../utils/date";
import { indexTheme } from "../styles/themes";
import { IOrdersFilters } from "../types/orders";
import "../styles/index.css";
import { formatAPIRequestErrorMessage } from "../utils/format";
import { errorMessages } from "../utils/constants";

const OrdersPage = () => {
  const {
    state,
    dispatch,
    getOrdersWithFilters,
    getContractPerimeters,
    getCompanies,
  } = useAppContext();
  const { accounts } = useMsal();
  const token = useAuthToken();

  const [showFilters, setShowFilters] = useState(true);
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    if (accounts.length !== 0 && accounts[0].name) {
      dispatch({ type: "SET_USER_NAME", payload: { name: accounts[0].name } });
    }
  }, [accounts, dispatch]);

  useEffect(() => {
    const loadOrders = async () => {
      if (token) {
        dispatch({ type: "GET_ORDERS_START" });

        const filters: IOrdersFilters = {
          delivery_start_date__gte: formatInputParisDateandTimetoUTCISO8601(
            getParisDay(1)
          ),
          delivery_end_date__lte: formatInputParisDateandTimetoUTCISO8601(
            getParisDay(2)
          ),
        };

        try {
          const ordersResponse = await getOrdersWithFilters(filters, token);
          dispatch({
            type: "GET_ORDERS_SUCCESS",
            payload: ordersResponse.items,
          });
          dispatch({
            type: "TABLE_INFO_UPDATED",
            payload: {
              totalOrders: ordersResponse.total,
              page: ordersResponse.page,
              pages: ordersResponse.pages,
            },
          });

          const contractResponse = await getContractPerimeters(token);
          dispatch({
            type: "GET_CONTRACT_PERIMETERS_SUCCESS",
            payload: contractResponse.items,
          });

          const companiesResponse = await getCompanies(token);
          dispatch({
            type: "GET_COMPANIES_SUCCESS",
            payload: companiesResponse.items,
          });
        } catch (error: any) {
          const errorMessage = formatAPIRequestErrorMessage(error, errorMessages.GET_DATA_ORDERSPAGE);
          dispatch({
            type: "SET_NOTIFICATION",
            payload: { message: errorMessage, type: "error" },
          });
          dispatch({ type: "GET_ORDERS_ERROR", payload: errorMessage });
        }
      }
    };

    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch]);

  return (
    <div>
      {state.status !== "succeeded" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <main style={{ display: "flex", marginTop: "20px" }}>
          <div
            style={{
              flex: showFilters ? "1" : "0",
              transition: "flex linear 0.3s",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "4px",
              }}
            >
              <span
                style={{
                  width: "80%",
                  height: "2px",
                  background:
                    "linear-gradient(90deg, rgba(68,145,85,1) 30%, rgba(255,217,0,1) 100%)",
                }}
              ></span>
              <button
                onClick={toggleFilters}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  padding: "0",
                  color: indexTheme.palette.primary.main,
                  border: "none",
                  backgroundColor: "rgba(134, 174, 57, 0.5)",
                }}
              >
                {showFilters ? <NavigateBefore /> : <NavigateNext />}
              </button>
            </div>

            <div style={{ display: showFilters ? "block" : "none" }}>
              <OrdersFilters />
            </div>
          </div>

          <div
            style={{
              flex: "8",
              display: "flex",
              flexDirection: "column",
              minWidth: "0",
              padding: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                transition: "flex 0.3s",
              }}
              className="ordersPageTopContainer"
            >
              <div
                style={{
                  flex: "4",
                  minWidth: "0",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <OrdersDashboard />
              </div>

              <div style={{ flex: "1", minWidth: "0" }}>
                <OrdersButtons />
              </div>
            </div>

            <div style={{ flex: "2", minWidth: "0" }}>
              <OrdersTabs />
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default OrdersPage;
