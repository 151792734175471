import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useAuthToken } from "../../app/hooks/useAuthToken";
import { useAppContext } from "../../DataProvider";
import { IOrder } from "../../types/orders";
import { formatAPIRequestErrorMessage } from "../../utils/format";
import { errorMessages } from "../../utils/constants";

interface IDeleteOrderFormProps {
  open: boolean;
  onClose: () => void;
  order: IOrder;
}

const DeleteOrderForm: React.FC<IDeleteOrderFormProps> = ({
  open,
  onClose,
  order,
}) => {
  const token = useAuthToken();
  const { deleteOrder } = useAppContext();
  const [orderStatus, setOrderStatus] = React.useState<
    "success" | "error" | null
  >(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOrderStatus(null);
    setErrorMessage("");
    onClose();
  };

  const handleConfirmDelete = async () => {
    if (!token) return;
    setLoading(true);
    try {
      await deleteOrder(token, order.id);
      setOrderStatus("success");
    } catch (error: any) {
      const errorMessage = formatAPIRequestErrorMessage(error, errorMessages.DELETE_ORDER);
      setOrderStatus("error");
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "30vw",
        },
      }}
    >
      {loading ? (
        <>
          <DialogTitle>Deleting Order...</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CircularProgress />
            </FormControl>
          </DialogContent>
        </>
      ) : (
        <>
          {orderStatus === "success" ? (
            <>
              <DialogTitle>Order Deleted</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="success">
                    Your order has been successfully deleted.
                  </Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          ) : orderStatus === "error" ? (
            <>
              <DialogTitle>Deletion Error</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="error">{errorMessage}</Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>Delete Order</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="warning">
                    Are you sure you want to delete order #{order.id}?
                  </Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                  Delete Order
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default DeleteOrderForm;
