export const getOrdersParams = {
  size: "100",
}

export const filtersOptions = {
  status: ["not sent", "filled", "pending", "partially filled", "rejected"],
  nemo: ["NordPool"],
  market: ["Spot Day Ahead"],
};

export const filtersValues = {
  status: ["not_sent", "filled", "pending", "partially_filled", "rejected"],
  market: ["day_ahead"],
  nemo: ["nordpool"],
};

export const statusStyle = {
  "not sent": {
    background: "rgba(128,128,128, 0.1)",
    color: "rgb(128,128,128)",
  },
  "filled": {
    background: "rgba(68, 145, 85, 0.1)",
    color: "rgb(68, 145, 85)",
  },
  "partially filled": {
    background: "rgba(134, 174, 57, 0.1)",
    color: "rgba(134, 174, 57, 1)",
  },
  "pending": {
    background: "rgba(65,105,225, 0.1)",
    color: "rgb(65,105,225)",
  },
  "rejected": {
    background: "rgba(231, 65, 27, 0.1)",
    color: "rgb(231, 65, 27)",
  }
};

export const contractPerimeterStyle = {
  "IE_CHARGE": {
    background: "rgba(68, 105, 88, 0.1)",
    color: "rgba(68, 105, 88, 1)",
  },
  "SGE23": {
    background: "rgba(134, 174, 57, 0.1)",
    color: "rgba(134, 174, 57, 1)",
  },
  "NWRE_JBOX": {
    background: "rgba(187, 239, 84, 0.1)",
    color: "rgba(187, 239, 84, 1)",
  },
  "SPOT_ARBITRAGE": {
    background: "rgba(65, 105, 225, 0.1)",
    color: "rgba(65, 105, 225)",
  },
  "PV_BARDE": {
    background: "rgba(122, 182, 150, 0.1)",
    color: "rgba(122, 182, 150, 1)",
  },
  "ARENH": {
    background: "rgba(178, 175, 56, 0.1)",
    color: "rgba(178, 175, 56, 1)",
  }
};

export const errorMessages = {
  "default": "An error occurred while processing your request. Please try again later.",
  "GET_ORDERS": "An error occurred while fetching orders. Please try again later.",
  "GET_DATA_ORDERSPAGE": "An error occurred while fetching data for orders page. Please try again later.",
  "GET_ORDERS_FILTERS": "An error occurred while fetching orders for filtering. Please try again later.",
  "GET_ORDERS_PAGINATION": "An error occurred while fetching orders for pagination. Please try again later.",
  "GET_ORDERS_SORT": "An error occurred while fetching orders for sorting. Please try again later.",
  "GET_COMPANIES": "An error occurred while fetching companies. Please try again later.",
  "GET_CONTRACT_PERIMETERS": "An error occurred while fetching contract perimeters. Please try again later.",
  "CREATE_ORDER": "An error occurred while creating the order. Please try again later.",
  "UPDATE_ORDER": "An error occurred while updating the order. Please try again later.",
  "DELETE_ORDER": "An error occurred while deleting the order. Please try again later.",
};
