import React from "react";
import { Box, Typography } from "@mui/material";
import { useAppContext } from "../../DataProvider"; 

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
  const { state } = useAppContext(); 
  const orders = state.orders; 

  const totalVolumeOfOrders = orders.reduce((sum, order) => {
    if (order.side === "SELL") {
      return sum + order.volume;
    } else if (order.side === "BUY") {
      return sum - order.volume;
    }
    return sum;
  }, 0);

  const totalNumberOfOrders = new Set(orders.map((order) => order.id)).size;

  const totalVolumeTraded = orders.reduce((sum, order) => {
    if (order.side === "SELL") {
      return sum + order.accepted_volume;
    } else if (order.side === "BUY") {
      return sum - order.accepted_volume;
    }
    return sum;
  }, 0);
  

  const totalBalance = orders.reduce(
    (sum, order) => sum + order.supplier_balance,
    0
  );

  return (
    <Box
      p={2}
      data-testid="Orders-Dashboard"
      sx={{
        border: "1px solid black",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "bold",
          fontStyle: "italic",
          mb: 1,
        }}
      >
        In current selection:
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Number of Orders: {isNaN(totalNumberOfOrders) ? "-" : totalNumberOfOrders}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Volume of Orders (MW): {isNaN(totalVolumeOfOrders) ? "-" : totalVolumeOfOrders.toFixed(2)}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Volume Traded (MW): {isNaN(totalVolumeTraded) ? "-" : totalVolumeTraded.toFixed(2)}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Balance (EUR): {isNaN(totalBalance) ? "-" : totalBalance.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Dashboard;
