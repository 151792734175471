import React, { useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAuthToken } from "../../app/hooks/useAuthToken";

import {
  validateNonEmpty,
  validatePrice,
  validateVolume,
} from "../../utils/validation";
import { convertOrderDataToIPatchOrder, formatAPIRequestErrorMessage } from "../../utils/format";
import { useAppContext } from "../../DataProvider";
import { IOrder, IPatchOrder } from "../../types/orders";
import { errorMessages } from "../../utils/constants";

interface IModifyOrderFormProps {
  open: boolean;
  onClose: () => void;
  order: IOrder;
}

const ModifyOrderForm: React.FC<IModifyOrderFormProps> = ({
  open,
  onClose,
  order,
}) => {
  const token = useAuthToken();
  const { patchOrder } = useAppContext();
  const [price, setPrice] = useState(order.price.toString());
  const [volume, setVolume] = useState(order.volume.toString());
  const [side, setSide] = useState(order.side);
  const [note, setNote] = useState(order.note);

  const [orderStatus, setOrderStatus] = useState<"success" | "error" | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [inputErrors, setInputErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOrderStatus(null);
    setErrorMessage("");
    setInputErrors({});
    onClose();
  };

  const validateRequiredFields = (orderData: any) => {
    const errors: Record<string, string> = {};
    for (const key in orderData) {
      if (key !== "note" && !validateNonEmpty(orderData[key], key)) {
        errors[key] = "Input needed";
      }
      if (key === "price" && !validatePrice(orderData[key])) {
        errors[key] = "Price should be between -500 and 4000 euros";
      }
      if (key === "volume" && !validateVolume(orderData[key])) {
        errors[key] = "Volume should be between 0.1 and 5 MW";
      }
    }
    setInputErrors(errors);
    if (Object.keys(errors).length === 0) setInputErrors({});
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async () => {
    const orderData = convertOrderDataToIPatchOrder({
      price,
      volume,
      side,
      note,
    });
    if (!validateRequiredFields(orderData) || !token) {
      return;
    }
    setLoading(true);
    try {
      await handleSubmitOrder(token, orderData);
      setOrderStatus("success");
    } catch (error: any) {
      setOrderStatus("error");
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOrder = async (
    token: string,
    orderData: IPatchOrder
  ): Promise<any> => {
    try {
      const response = await patchOrder(token, order.id, orderData);
      return response;
    } catch (error: any) {
      const errorMessage = formatAPIRequestErrorMessage(error, errorMessages.UPDATE_ORDER);
      throw new Error(errorMessage);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "30vw",
        },
      }}
    >
      {loading ? (
        <>
          <DialogTitle>Updating Order...</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CircularProgress />
            </FormControl>
          </DialogContent>
        </>
      ) : (
        <>
          {orderStatus === "success" ? (
            <>
              <DialogTitle>Order Updated</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="success">
                    Your order has been successfully updated.
                  </Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          ) : orderStatus === "error" ? (
            <>
              <DialogTitle>Update Error</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="error">{errorMessage}</Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>Update Order</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  label="Price"
                  type="number"
                  data-testid="price-input"
                  fullWidth
                  variant="outlined"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
                {inputErrors.price && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.price}</Alert>
                  </FormControl>
                )}
                <TextField
                  margin="dense"
                  label="Volume"
                  type="number"
                  data-testid="volume-input"
                  fullWidth
                  variant="outlined"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                  required
                />
                {inputErrors.volume && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.volume}</Alert>
                  </FormControl>
                )}
                <FormControl fullWidth margin="dense">
                  <InputLabel id="side-select-label">Side</InputLabel>
                  <Select
                    labelId="side-select-label"
                    id="side-select"
                    data-testid="side-select"
                    value={side}
                    label="Side"
                    onChange={(e) => setSide(e.target.value)}
                    required
                  >
                    <MenuItem value="BUY">BUY</MenuItem>
                    <MenuItem value="SELL">SELL</MenuItem>
                  </Select>
                </FormControl>
                {inputErrors.side && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.side}</Alert>
                  </FormControl>
                )}
                <TextField
                  margin="dense"
                  label="Note"
                  data-testid="note-input"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
                <Button onClick={handleFormSubmit} color="primary">
                  Update
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default ModifyOrderForm;
