import { addHours } from "date-fns";
import { IPatchOrder, IPostOrder } from "../types/orders";
import { filtersOptions, filtersValues } from "./constants";
import { formatInputUTCDateandTimetoISO8601 } from "./date";

export const convertOptiontoValue = (
  option: string,
  valueType: string
): string => {
  let options;
  let values;
  if (valueType === "market") {
    options = filtersOptions.market;
    values = filtersValues.market;
  } else if (valueType === "status") {
    options = filtersOptions.status;
    values = filtersValues.status;
  } else {
    options = filtersOptions.nemo;
    values = filtersValues.nemo;
  }
  const index = options.indexOf(option);
  return index !== -1 ? values[index] : "";
};

export const convertValueToOption = (
  value: string,
  valueType: string
): string => {
  let options;
  let values;
  if (valueType === "market") {
    options = filtersOptions.market;
    values = filtersValues.market;
  } else if (valueType === "status") {
    options = filtersOptions.status;
    values = filtersValues.status;
  } else {
    options = filtersOptions.nemo;
    values = filtersValues.nemo;
  }
  const index = values.indexOf(value);
  return index !== -1 ? options[index] : "";
};

export const convertOrderDataToIPostOrder = (orderData: {
  price: string;
  volume: string;
  side: string;
  contract: string;
  startDate: string;
  nemo: string;
  market: string;
  startHour: string;
  endHour: string;
  note?: string;
}): IPostOrder => {
  const delivery_start_date = formatInputUTCDateandTimetoISO8601(
    orderData.startDate,
    orderData.startHour
  );
  const timeDelta = +orderData.endHour - +orderData.startHour;
  const delivery_end_date = addHours(
    new Date(delivery_start_date),
    timeDelta
  ).toISOString();

  return {
    delivery_start_date,
    delivery_end_date,
    volume: Number(orderData.volume),
    price: Number(orderData.price),
    contract_perimeter_id: orderData.contract,
    nemo: convertToFilterValue(
      orderData.nemo,
      filtersOptions.nemo,
      filtersValues.nemo
    ),
    market: convertOptiontoValue(orderData.market, "market"),
    side: orderData.side,
    note: orderData.note,
  };
};

export const convertOrderDataToIPatchOrder = (orderData: {
  price: string;
  volume: string;
  side: string;
  note?: string;
}): IPatchOrder => {
  return {
    volume: Number(orderData.volume),
    price: Number(orderData.price),
    side: orderData.side,
    note: orderData.note,
  };
};

export const convertToFilterValue = (
  selectedValue: string,
  options: string[],
  values: string[]
) => {
  const index = options.findIndex(
    (option) => option.toLowerCase() === selectedValue.toLowerCase()
  );
  return index !== -1 ? values[index] : "";
};

export const convertToFilterValues = (
  selectedValues: string[],
  options: string[],
  values: string[]
) => {
  return selectedValues.map((selectedValue) => {
    const index = options.findIndex(
      (option) => option.toLowerCase() === selectedValue.toLowerCase()
    );
    return index !== -1 ? values[index] : "";
  });
};

export const formatAPIRequestErrorMessage = (
  error: any,
  defaultErrorMessage: string
): string => {
  let errorMessage = defaultErrorMessage;

  if (error.response && Array.isArray(error.response.data)) {
    const firstError = error.response.data[0];
    if (firstError && firstError.message) {
      errorMessage = firstError.message;
    }
  } else if (error.message) {
    errorMessage = error.message;
  }

  return errorMessage;
};
