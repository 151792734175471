import React from "react";
import { useLocation } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { useAppContext } from "../../DataProvider";
import logo from "../../assets/whiteLogoNW.png";
import profileDefault from "../../assets/profileDefault.svg";

interface INavbarProps {
  onOrdersClick: () => void;
}

const Navbar: React.FC<INavbarProps> = ({ onOrdersClick }) => {
  const { instance } = useMsal();
  const { state, dispatch } = useAppContext();
  const location = useLocation();

  const userName = state.name;

  const isOrdersPage = location.pathname === "/";

  const handleLogout = () => {
    instance
      .logoutPopup()
      .then((response) => {
        instance.loginRedirect();
      })
      .catch((e) => {
        const errorMessage = e.errorMessage || e.message || "Unknown Error";
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            message: `Error disconnecting: ${errorMessage}`,
            type: "error",
          },
        });
      });
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background:
            "linear-gradient(90deg, rgba(68,145,85,1) 30%, rgba(255,217,0,1) 100%)",
        }}
      >
        <Toolbar>
          <img
            src={logo}
            alt="logo"
            style={{ marginRight: "10px", height: "40px" }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              color="inherit"
              onClick={onOrdersClick}
              sx={{ opacity: isOrdersPage ? 1 : 0.5 }}
            >
              NWRE Market Access
            </Button>
          </Box>
          {userName && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginRight: "10px",
              }}
            >
              <Avatar src={profileDefault} alt={userName} />
              <Typography sx={{ color: "white" }}>
                {userName}
              </Typography>
            </Box>
          )}
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
