import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useAuthToken } from "../../app/hooks/useAuthToken";
import { useAppContext } from "../../DataProvider";
import { filtersOptions, errorMessages } from "../../utils/constants";
import { generateHourList, getParisDay } from "../../utils/date";
import {
  convertOrderDataToIPostOrder,
  formatAPIRequestErrorMessage,
} from "../../utils/format";
import {
  validateNonEmpty,
  validatePrice,
  validateVolume,
} from "../../utils/validation";
import { IPostOrder } from "../../types/orders";

interface ICreateOrderFormProps {
  open: boolean;
  onClose: () => void;
}

const CreateOrderForm: React.FC<ICreateOrderFormProps> = ({
  open,
  onClose,
}) => {
  const token = useAuthToken();
  const { state, postOrder } = useAppContext();
  const contractPerimeters = state.contractPerimeters;
  const tomorrow = getParisDay(1);
  const [price, setPrice] = useState("");
  const [volume, setVolume] = useState("");
  const [side, setSide] = useState("");
  const [nemo, setNemo] = useState("");
  const [note, setNote] = useState("");
  const [market, setMarket] = useState("");
  const [contract, setContract] = useState("");
  const [startDate, setStartDate] = useState(tomorrow);
  const [startHour, setStartHour] = useState("00");
  const [endHour, setEndHour] = useState("");
  const [orderStatus, setOrderStatus] = useState<"success" | "error" | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [inputErrors, setInputErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (parseInt(startHour) >= parseInt(endHour)) {
      if (parseInt(endHour) < 23) {
        setEndHour((parseInt(startHour) + 1).toString().padStart(2, "0"));
      } else {
        setEndHour("00");
      }
    }
  }, [startHour, endHour]);

  const handleResetForm = () => {
    setPrice("");
    setVolume("");
    setSide("");
    setContract("");
    setNemo("");
    setMarket("");
    setStartHour("00");
    setEndHour("");
    setNote("");
    setStartDate(tomorrow);
    setOrderStatus(null);
    setErrorMessage("");
    setInputErrors({});
  };

  const handleRetry = () => {
    setOrderStatus(null);
    setErrorMessage("");
    setInputErrors({});
  };

  const handleClose = () => {
    handleResetForm();
    onClose();
  };

  const validateRequiredFields = (orderData: any) => {
    const errors: Record<string, string> = {};
    for (const key in orderData) {
      if (
        key !== "delivery_end_date" &&
        key !== "note" &&
        !validateNonEmpty(orderData[key], key)
      ) {
        errors[key] = "Input needed";
      }
      if (key === "price" && !validatePrice(orderData[key])) {
        errors[key] = "Price should be between -500 and 4000 euros";
      }
      if (key === "volume" && !validateVolume(orderData[key])) {
        errors[key] = "Volume should be between 0.1 and 5 MW";
      }
    }
    setInputErrors(errors);
    if (Object.keys(errors).length === 0) setInputErrors({});
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async () => {
    const orderData = convertOrderDataToIPostOrder({
      price,
      volume,
      side,
      contract,
      startDate,
      nemo,
      market,
      startHour,
      endHour,
      note,
    });
    if (!validateRequiredFields(orderData) || !token) {
      return;
    }
    setLoading(true);
    try {
      await handleSubmitOrder(token, orderData);
      setOrderStatus("success");
    } catch (error: any) {
      setOrderStatus("error");
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOrder = async (
    token: string,
    orderData: IPostOrder
  ): Promise<any> => {
    try {
      const response = await postOrder(token, orderData);
      return response;
    } catch (error: any) {
      const errorMessage = formatAPIRequestErrorMessage(
        error,
        errorMessages.CREATE_ORDER
      );
      throw new Error(errorMessage);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "30vw",
        },
      }}
    >
      {loading ? (
        <>
          <DialogTitle>Submiting Order...</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth
              margin="dense"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CircularProgress />
            </FormControl>
          </DialogContent>
        </>
      ) : (
        <>
          {orderStatus === "success" ? (
            <>
              <DialogTitle>Order Submitted</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="success">
                    Your order has been successfully submitted.
                  </Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRetry} color="primary">
                  New Order
                </Button>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          ) : orderStatus === "error" ? (
            <>
              <DialogTitle>Submission Error</DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="dense">
                  <Alert severity="error">{errorMessage}</Alert>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRetry} color="primary">
                  Retry
                </Button>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>Create New Order</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  label="Price"
                  type="number"
                  data-testid="price-input"
                  fullWidth
                  variant="outlined"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
                {inputErrors.price && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.price}</Alert>
                  </FormControl>
                )}
                <TextField
                  margin="dense"
                  label="Volume"
                  type="number"
                  data-testid="volume-input"
                  fullWidth
                  variant="outlined"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                  required
                />
                {inputErrors.volume && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.volume}</Alert>
                  </FormControl>
                )}
                <FormControl fullWidth margin="dense">
                  <InputLabel id="side-select-label" required>
                    Side
                  </InputLabel>
                  <Select
                    labelId="side-select-label"
                    id="side-select"
                    data-testid="side-select"
                    value={side}
                    label="Side"
                    onChange={(e) => setSide(e.target.value)}
                  >
                    <MenuItem value="BUY">BUY</MenuItem>
                    <MenuItem value="SELL">SELL</MenuItem>
                  </Select>
                </FormControl>
                {inputErrors.side && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.side}</Alert>
                  </FormControl>
                )}
                <FormControl fullWidth margin="dense">
                  <InputLabel id="nemo-select-label" required>
                    Nemo
                  </InputLabel>
                  <Select
                    labelId="nemo-select-label"
                    id="nemo-select"
                    data-testid="nemo-select"
                    value={nemo}
                    label="Nemo"
                    onChange={(e) => setNemo(e.target.value)}
                  >
                    {filtersOptions.nemo.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {inputErrors.nemo && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.nemo}</Alert>
                  </FormControl>
                )}
                <FormControl fullWidth margin="dense">
                  <InputLabel id="market-select-label" required>
                    Market
                  </InputLabel>
                  <Select
                    labelId="market-select-label"
                    id="market-select"
                    data-testid="market-select"
                    value={market}
                    label="Market"
                    onChange={(e) => setMarket(e.target.value)}
                  >
                    {filtersOptions.market.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {inputErrors.market && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.market}</Alert>
                  </FormControl>
                )}
                <FormControl fullWidth margin="dense">
                  <InputLabel id="contract-select-label" required>
                    Contract
                  </InputLabel>
                  <Select
                    labelId="contract-select-label"
                    id="contract-select"
                    data-testid="contract-select"
                    value={contract}
                    label="Contract"
                    onChange={(e) => setContract(e.target.value.toString())}
                  >
                    {contractPerimeters.map((contract) => (
                      <MenuItem key={contract.id} value={contract.id}>
                        {contract.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {inputErrors.contract_perimeter_id && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">
                      {inputErrors.contract_perimeter_id}
                    </Alert>
                  </FormControl>
                )}
                <TextField
                  margin="dense"
                  label="Note"
                  data-testid="note-input"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Start Date"
                  type="date"
                  data-testid="start-date-input"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                {inputErrors.startDate && (
                  <FormControl fullWidth margin="dense">
                    <Alert severity="error">{inputErrors.startDate}</Alert>
                  </FormControl>
                )}
                <Grid container spacing={2} pt={1}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="start-hour-select-label" required>
                        Start Hour
                      </InputLabel>
                      <Select
                        labelId="start-hour-select-label"
                        id="start-hour-select"
                        data-testid="start-hour-select"
                        value={startHour}
                        label="Start Hour"
                        onChange={(e) => setStartHour(e.target.value as string)}
                        required
                      >
                        {generateHourList("start").map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="end-hour-select-label">
                        End Hour
                      </InputLabel>
                      <Select
                        labelId="end-hour-select-label"
                        id="end-hour-select"
                        data-testid="end-hour-select"
                        value={endHour}
                        label="End Hour"
                        onChange={(e) => setEndHour(e.target.value as string)}
                        required
                      >
                        {generateHourList("end").map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleFormSubmit} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default CreateOrderForm;
