import React from "react";
import { Typography } from "@mui/material";

interface IOrdersGraphProps {
}

const OrdersGraph: React.FC<IOrdersGraphProps> = () => {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <Typography variant="h4" style={{ flexGrow: 1 }}>
        Graph Tab
      </Typography>
    </div>
  );
};

export default OrdersGraph;
