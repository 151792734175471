import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import OrdersTable from "./OrdersTable";
import OrdersGraph from "./OrdersGraphs";
import ModifyOrderForm from "./ModifyOrderForm";
import DeleteOrderForm from "./DeleteOrderForm";
import { IOrder } from "../../types/orders";
import { useAppContext } from "../../DataProvider";
import { useAuthToken } from "../../app/hooks/useAuthToken";
import { formatAPIRequestErrorMessage } from "../../utils/format";
import { errorMessages } from "../../utils/constants";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
    </div>
  );
}

interface ITabsProps {}

const OrdersTabs: React.FC<ITabsProps> = () => {
  const token = useAuthToken();
  const { state, dispatch, getOrdersWithFilters } = useAppContext();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isModifyOrderOpen, setIsModifyOrderOpen] = useState(false);
  const [isDeleteOrderOpen, setIsDeleteOrderOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);

  const handleModifyOrderClick = (order: IOrder) => {
    setSelectedOrder(order);
    setIsModifyOrderOpen(true);
  };

  const handleDeleteOrderClick = (order: IOrder) => {
    setSelectedOrder(order);
    setIsDeleteOrderOpen(true);
  };

  const handleReload = async () => {
    if (token)
      try {
        const ordersResponse = await getOrdersWithFilters(state.filters, token);
        dispatch({ type: "GET_ORDERS_SUCCESS", payload: ordersResponse.items });
        dispatch({
          type: "TABLE_INFO_UPDATED",
          payload: {
            totalOrders: ordersResponse.total,
            page: ordersResponse.page,
            pages: ordersResponse.pages,
          },
        });
      } catch (error: any) {
        const errorMessage = formatAPIRequestErrorMessage(
          error,
          errorMessages.GET_ORDERS
        );
        dispatch({
          type: "SET_NOTIFICATION",
          payload: { message: errorMessage, type: "error" },
        });
      }
  };

  const handleCloseModifyOrder = () => {
    setIsModifyOrderOpen(false);
    handleReload();
  };

  const handleCloseDeleteOrder = () => {
    setIsDeleteOrderOpen(false);
    handleReload();
  };

  return (
    <Box sx={{ width: "100%" }} data-testid="Orders-Tabs">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "white",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Table" />
          <Tab label="Graphs" disabled />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OrdersTable
          onEdit={handleModifyOrderClick}
          onDelete={handleDeleteOrderClick}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrdersGraph />
      </TabPanel>
      {isModifyOrderOpen && selectedOrder && (
        <ModifyOrderForm
          open={isModifyOrderOpen}
          onClose={handleCloseModifyOrder}
          order={selectedOrder}
        />
      )}

      {isDeleteOrderOpen && selectedOrder && (
        <DeleteOrderForm
          open={isDeleteOrderOpen}
          onClose={handleCloseDeleteOrder}
          order={selectedOrder}
        />
      )}
    </Box>
  );
};

export default OrdersTabs;
