import { useState, useEffect } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";


const scopes: string[] = [process.env.REACT_APP_MSAL_SCOPE as string]

export const useAuthToken = (): string | undefined => {
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inProgress === "none" && accounts && accounts.length > 0) {
      const request = {
        scopes: scopes,
        account: accounts[0] as AccountInfo | undefined,
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setToken(response.accessToken);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération du token d'accès",
            error
          );
          setToken(undefined);
        });
    }
  }, [instance, accounts, inProgress]);
  return token;
};
