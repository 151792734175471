export const validateNonEmpty = (value: string | number, fieldName: string): boolean => {
    if (!value) {
        return false;
    }
    const valueStr = typeof value === 'number' ? value.toString() : value;
    if (valueStr.trim() === "") {
        return false;
    }
    return true;
};

  
export const validatePrice = (value: string): boolean => {
  const numericValue = parseFloat(value);
  if (isNaN(numericValue) || numericValue < -500 || numericValue > 4000) {
    return false;
  }
  return true;
};

export const validateVolume = (value: string): boolean => {
  const numericValue = parseFloat(value);
  if (isNaN(numericValue) || numericValue < 0.1 || numericValue > 5) {
    return false;
  }
  return true;
};
