import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";

import "./styles/index.css";
import { indexTheme } from "./styles/themes";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);



root.render(
  <React.StrictMode>
    <ThemeProvider theme={indexTheme}>

        <App />

    </ThemeProvider>
  </React.StrictMode>
);
