import React, { useState } from "react";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useAppContext } from "../../DataProvider";
import CreateOrderForm from "./CreateOrderForm";
import { indexTheme } from "../../styles/themes";
import "../../styles/index.css";

interface IOrdersButtonsProps {}

const OrdersButtons: React.FC<IOrdersButtonsProps> = () => {
  const { state } = useAppContext();
  const orders = state.orders;
  const [isCreateOrderOpen, setIsCreateOrderOpen] = useState(false);

  const handleNewOrderClick = () => {
    setIsCreateOrderOpen(true);
  };

  const handleCloseCreateOrder = () => {
    setIsCreateOrderOpen(false);
  };

  const downloadAsCSV = () => {
    const ordersData = orders.map((order) => ({
      id: order.id,
      delivery_start_date: order.delivery_start_date,
      status: order.status,
      volume: order.volume,
      accepted_volume: order.accepted_volume,
      price: order.price,
      clearing_price: order.clearing_price,
      updated_at: order.updated_at,
      supplier_balance: order.supplier_balance,
      side: order.side,
      company: order.company,
      contract_perimeter_id: order.contract_perimeter_id,
      nemo: order.nemo,
      market: order.market,
      note: order.note,
    }));

    const ws = XLSX.utils.json_to_sheet(ordersData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    const today = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const deliveryStartDate = orders[0].delivery_start_date;
    const fileName = `${today}_orders_selection_${deliveryStartDate}.csv`;

    XLSX.writeFile(wb, fileName);
  };

  return (
    <div
      data-testid="Orders-Buttons"
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
      className="ordersPageButtonContainer"
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: indexTheme.palette.primary.light,
          "&:hover": {
            backgroundColor: indexTheme.palette.secondary.main,
          },
        }}
        onClick={handleNewOrderClick}
      >
        New Order
      </Button>
      <CreateOrderForm
        open={isCreateOrderOpen}
        onClose={handleCloseCreateOrder}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: indexTheme.palette.primary.dark,
          "&:hover": {
            backgroundColor: indexTheme.palette.secondary.main,
          },
        }}
        onClick={downloadAsCSV}
      >
        Download
      </Button>
    </div>
  );
};

export default OrdersButtons;
