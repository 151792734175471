import React, {
  createContext,
  useContext,
  useReducer,
  PropsWithChildren,
} from "react";
import axios from "axios";
import { IOrdersFilters, IPostOrder  } from "./types/orders";
import { IAppProviderState } from "./types/state";
import {
  IOrder,
  ICompany,
  IContractPerimeter,
  IGetOrderResponse,
  IGetContractsPerimeterResponse,
  IGetCompaniesResponse,
} from "./types/orders";
import { getOrdersParams } from "./utils/constants";

const baseURL = process.env.REACT_APP_API_BASE_URL as string;

const initialState: IAppProviderState = {
  orders: [],
  status: "idle",
  companies: [],
  contractPerimeters: [],
  filters: {},
  totalOrders: 0,
  page: 1,
  pages: 1,
  name: "",
  photoUrl: "",
  notification: { message: "", type: null },
};

export type AppAction =
  | { type: "GET_ORDERS_START" }
  | { type: "GET_ORDERS_SUCCESS"; payload: IOrder[] }
  | { type: "GET_ORDERS_ERROR"; payload: any }
  | { type: "GET_COMPANIES_SUCCESS"; payload: ICompany[] }
  | { type: "GET_CONTRACT_PERIMETERS_SUCCESS"; payload: IContractPerimeter[] }
  | { type: "FILTERS_UPDATED"; payload: IOrdersFilters }
  | {
      type: "TABLE_INFO_UPDATED";
      payload: { totalOrders: number; page: number; pages: number };
    }
  | { type: "SET_USER_NAME"; payload: { name: string } }
  | {
      type: "SET_NOTIFICATION";
      payload: {
        message: string;
        type: "error" | "success" | "info" | "warning";
      };
    }
  | { type: "CLEAR_NOTIFICATION" };

export const appReducer = (
  state: IAppProviderState,
  action: AppAction
): IAppProviderState => {
  switch (action.type) {
    case "GET_ORDERS_START":
      return { ...state, status: "loading" };
    case "GET_ORDERS_SUCCESS":
      return { ...state, orders: action.payload, status: "succeeded" };
    case "GET_ORDERS_ERROR":
      return { ...state, status: "failed" };
    case "GET_COMPANIES_SUCCESS":
      return { ...state, companies: action.payload };
    case "GET_CONTRACT_PERIMETERS_SUCCESS":
      return { ...state, contractPerimeters: action.payload };
    case "FILTERS_UPDATED":
      return { ...state, filters: action.payload };
    case "TABLE_INFO_UPDATED":
      return {
        ...state,
        totalOrders: action.payload.totalOrders,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case "SET_USER_NAME":
      return { ...state, name: action.payload.name };
    case "SET_NOTIFICATION":
      return {
        ...state,
        notification: {
          message: action.payload.message,
          type: action.payload.type,
        },
      };
    case "CLEAR_NOTIFICATION":
      return { ...state, notification: { message: "", type: null } };
    default:
      return state;
  }
};

const AppContext = createContext<
  | {
      state: IAppProviderState;
      dispatch: React.Dispatch<AppAction>;
      getOrdersWithFilters: (
        filters: IOrdersFilters,
        token: string
      ) => Promise<IGetOrderResponse>;
      getContractPerimeters: (
        token: string,
        filters?: Record<string, any>
      ) => Promise<IGetContractsPerimeterResponse>;
      getCompanies: (
        token: string,
        filters?: Record<string, any>
      ) => Promise<IGetCompaniesResponse>;
      postOrder: (token: string, orderData: IPostOrder) => Promise<void>;
      patchOrder: (
        token: string,
        orderId: number,
        orderData: {
          price: number;
          volume: number;
          side: string;
          note?: string;
        }
      ) => Promise<void>;
      deleteOrder: (token: string, orderId: number) => Promise<void>;
    }
  | undefined
>(undefined);

export const DataProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const getOrdersWithFilters = async (
    filters: IOrdersFilters,
    token: string
  ): Promise<IGetOrderResponse> => {
    let filteredParams = Object.entries(filters).reduce((acc, [key, value]) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, string>);

    filteredParams = { ...filteredParams, ...getOrdersParams };
    const params = new URLSearchParams(filteredParams).toString();

    try {
      const response = await axios.get<IGetOrderResponse>(
        `${baseURL}orders/?${params}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getContractPerimeters = async (
    token: string,
    filters: Record<string, any> = {}
  ): Promise<IGetContractsPerimeterResponse> => {
    try {
      const response = await axios.get<IGetContractsPerimeterResponse>(
        `${baseURL}contract_perimeter/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getCompanies = async (
    token: string,
    filters: Record<string, any> = {}
  ): Promise<IGetCompaniesResponse> => {
    try {
      const response = await axios.get<IGetCompaniesResponse>(
        `${baseURL}company/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const postOrder = async (
    token: string,
    orderData: IPostOrder
  ): Promise<void> => {
    const { delivery_end_date, ...rest } = orderData;
    const url = delivery_end_date
      ? `${baseURL}orders/range/`
      : `${baseURL}orders/`;
    try {
      await axios.post(
        url,
        { ...rest, ...(delivery_end_date && { delivery_end_date }) },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return;
    } catch (error) {
      throw error;
    }
  };

  const patchOrder = async (
    token: string,
    orderId: number,
    orderData: { price: number; volume: number; side: string; note?: string }
  ): Promise<void> => {
    const url = `${baseURL}orders/${orderId}`;
    try {
      await axios.patch(url, orderData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return;
    } catch (error) {
      throw error;
    }
  };

  const deleteOrder = async (token: string, orderId: number): Promise<void> => {
    const url = `${baseURL}orders/${orderId}`;
    try {
      await axios.delete(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return;
    } catch (error) {
      throw error;
    }
  };

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        getOrdersWithFilters,
        getContractPerimeters,
        getCompanies,
        postOrder,
        patchOrder,
        deleteOrder,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a DataProvider");
  }
  return context;
};
