import { createTheme } from "@mui/material/styles";

export const indexTheme = createTheme({
    palette: {
      primary: {
        main: "#449155",
        light: "#86AE39",
        dark: "#446958",
      },
      secondary: {
        main: "#FFD900",
      },
      error: {
        main: "#E7411B",
      },
  
      warning: {
        main: "#F39433",
      },
      info: {
        main: "#41C0F0",
      },
      success: {
        main: "#BBEF54",
      },
    },
    typography: {
      fontFamily: "Roboto Condensed, Arial, sans-serif",
      allVariants: {
        color: "#3C4658",
      },
    },
  });