import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance } from "./app/configs/authConfig";
import OrdersPage from "./Pages/OrdersPage";
import Navbar from "./components/layout/Navbar";
import Notification from "./components/layout/Notifications";
import { DataProvider } from "./DataProvider";

function App() {
  const handleOrdersClick = () => {
    console.log("Orders clicked");
  };

  return (
    <MsalProvider instance={msalInstance}>
      <DataProvider>
        <Router>
          <Navbar onOrdersClick={handleOrdersClick} />
          <Notification />
          <Routes>
            <Route
              path="/"
              element={
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                >
                  <OrdersPage />
                </MsalAuthenticationTemplate>
              }
            />
          </Routes>
        </Router>
      </DataProvider>
    </MsalProvider>
  );
}

export default App;
