import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
    authority: process.env.REACT_APP_MSAL_AUTHORITY as string,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
